import {ExternalLinkIcon} from '@chakra-ui/icons';
import {Avatar, Badge, Box, Card, CardBody, CardHeader, Flex, Heading, Link, Stack, Tag, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {mixpanelEventsUrl} from '../../helpers/params';
import UserModal from './UserModal';
import _ from 'lodash';
import {roles as rolesConsts} from 'common';

const {roleNames} = rolesConsts;


const User = ({
  user: {
    _id,
    aiMonthlyBudget,
    alias,
    buildNumber,
    dismissedHelpTypes,
    email,
    googleTilesKeyIndex,
    isAiDisabled,
    // isAmbassador,
    isDev,
    isFounder,
    isLoggedInToWhereOnEarth,
    isLoggedInToWooorld,
    isVIP,
    profilePhotoURL,
    reportedCount,
    reportedUsers,
    requiresVerification,
    roles = [],
    timeoutCount,
    timeouts,
    worldIsPrivate,
    worldIsSolo,
    worldIsWitw,
    worldName,
  }, user
}) => {

  // FIXME, this is server time
  const latestTimeout = _.last(_.sortBy(timeouts, t => Date.parse(t.expiration)));

  const {isLoading, response: rolesResponse} = useSelector(s => s.goood.permissionsAdmin.roles);
  const rolesAll = rolesResponse || [];

  const hasRole = (roleName) => {
    const roleConfig = rolesAll.find(r => r.name === roleName);
    if (!roleConfig) return false;
    return !!(roles.find(r => r === roleConfig._id));
  }

  const isAlpha = hasRole(roleNames.alphaTester);
  const isAmbassador = hasRole(roleNames.ambassador);
  const isAssistant1 = hasRole(roleNames.assistant1);
  const isAssistant2 = hasRole(roleNames.assistant2);
  const isAssistant3 = hasRole(roleNames.assistant3);
  const isAssistant1Trial7Day = hasRole(roleNames.assistant1_7Day);
  const isAssistant2Trial7Day = hasRole(roleNames.assistant2_7Day);
  const isAssistant3Trial7Day = hasRole(roleNames.assistant3_7Day);
  const isAssistant3Trial1Day = hasRole(roleNames.assistant3Trial1Day);
  const isAssistant3Trial6Month = hasRole(roleNames.assistant3Trial6Month);
  const isBeta = hasRole(roleNames.betaTester);
  const isExplorerPlus = hasRole(roleNames.explorerPlus);
  const isPremium2024 = hasRole(roleNames.premium2024);
  const isExplorerPlusFounder = hasRole(roleNames.explorerPlusFounder);
  const isModerator = hasRole(roleNames.moderator);
  const isPlayerPlus = hasRole(roleNames.playerPlus);
  const isPlayerPlusFounder = hasRole(roleNames.playerPlusFounder);
  const isSupporter1 = hasRole(roleNames.supporter1);
  const isSupporter2 = hasRole(roleNames.supporter2);
  const isSupporter3 = hasRole(roleNames.supporter3);
  const isTester = hasRole(roleNames.tester);


  return (
    <Card
      bg="whiteAlpha.700"
      borderRadius="2xl"
    >
      <CardHeader p={2}>
        {/* Top Row nowrap*/}
        <Flex flex="1" gap="1" alignItems="top" justifyContent="space-between" flexWrap="nowrap">

          <Flex alignItems="center" flexWrap="wrap" gap={1}>
            <Avatar
              borderColor={(isLoggedInToWooorld || isLoggedInToWhereOnEarth) ? 'lightgreen' : 'grey'}
              name={alias}
              showBorder
              size="sm"
              src={profilePhotoURL}
            />

            {buildNumber && <Tag title="build number" variant="outline">v{buildNumber}</Tag>}
            {isLoggedInToWhereOnEarth && <Tag title="WhereOnEarth" variant="outline">🎮</Tag>}
            {isLoggedInToWooorld && <Tag title="Wooorld" variant="outline">🌐</Tag>}
            {isDev && <Tag title="developer" variant="outline">👩‍💻</Tag>}
            {isModerator && <Tag title="moderator" variant="outline">🔨</Tag>}
            {isAmbassador && <Tag title="ambassador" variant="outline">☑️</Tag>}
            {isExplorerPlus && <Tag title="explorer+" variant="outline">🔵</Tag>}
            {isPremium2024 && <Tag title="premium2024" variant="outline">🟣</Tag>}
            {isPlayerPlus && <Tag title="player+" variant="outline">🔴</Tag>}
            {isExplorerPlusFounder && <Tag title="explorer+ founder" variant="outline">🔵F</Tag>}
            {isPlayerPlusFounder && <Tag title="player+ founder" variant="outline">🔴F</Tag>}

            {isAssistant1 && <Tag title="assistant1" variant="outline">🤖️1</Tag>}
            {isAssistant2 && <Tag title="assistant2" variant="outline">🤖️2</Tag>}
            {isAssistant3 && <Tag title="assistant3" variant="outline">🤖️3</Tag>}
            {isAssistant3Trial1Day && <Tag title="assistant3 1 day trial" variant="outline">🤖️1d</Tag>}
            {isAssistant3Trial6Month && <Tag title="assistant3 6 month trial" variant="outline">🤖️6m</Tag>}
            {isAssistant1Trial7Day && <Tag title="assistant1 7 day" variant="outline">🤖️1_7d</Tag>}
            {isAssistant2Trial7Day && <Tag title="assistant2 7 day" variant="outline">🤖️2_7d</Tag>}
            {isAssistant3Trial7Day && <Tag title="assistant3 7 day" variant="outline">🤖️3_7d</Tag>}

            {isSupporter1 && <Tag title="supporter1" variant="outline">🥉S1</Tag>}
            {isSupporter2 && <Tag title="supporter2" variant="outline">🥈S2</Tag>}
            {isSupporter3 && <Tag title="supporter3" variant="outline">🥇S3</Tag>}


            {isFounder && <Tag title="founder" variant="outline">F</Tag>}
            {isAlpha && <Tag title="alpha tester" variant="outline">🅰</Tag>}
            {isBeta && <Tag title="beta tester" variant="outline">🅱</Tag>}
            {isTester && <Tag title="tester" variant="outline">🧪</Tag>}
            {!!reportedCount && <Tag title="reported count" variant="outline">{`🚨x${reportedCount}`}</Tag>}
            {!!timeoutCount && <Tag title="timeout count" variant="outline">
              {`🪑x${timeoutCount}`}
              {latestTimeout && ` ${latestTimeout.expiration}`}
            </Tag>}
            {isVIP && <Tag variant="outline">🕴VIP</Tag>}
          </Flex>


          <UserModal user={user}/>
        </Flex>

      </CardHeader>

      <CardBody>
        <Stack gap={1}>
          <Heading size="sm">{alias}</Heading>
          <Heading size="xs">{email}</Heading>
          <Link href={mixpanelEventsUrl(_id)} isExternal>
            {_id} <ExternalLinkIcon mx="2px"/>
          </Link>

          {!!worldName && (
            <Badge
              bg={
                worldIsWitw
                  ? 'playerPink'
                  : 'explorerBlue'
              }
              borderRadius="2xl"
              paddingX={2}
              paddingY={1}
              textTransform="none"
            >
              <Heading size="xs">{worldName}</Heading>
              <Text>
                {worldIsWitw ? '🕹' : '🧭'} &nbsp;
                {`${worldIsSolo ? 'Solo' : worldIsPrivate ? 'Private' : 'Public'} ${worldIsWitw ? 'Game' : 'Exploration'} World`}
                <br/>
                Tiles Key: {googleTilesKeyIndex}
              </Text>
            </Badge>
          )}


        </Stack>
      </CardBody>
    </Card>);
};

export default User;
