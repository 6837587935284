import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Avatar, Box,
  Button, Flex, Heading,
  IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import {BsThreeDotsVertical} from 'react-icons/bs';
import UserMessage from './UserMessage';
import UserTimeout from './UserTimeout';
import CreditsTable from './PermissionsAdmin/CreditsTable';


const UserModal = ({user}) => {
  const {isOpen, onToggle, onClose} = useDisclosure();

  return (
    <>
      <IconButton
        colorScheme="wooorldBlue"
        icon={<BsThreeDotsVertical/>}
        isRound
        onClick={onToggle}
        variant="ghost"
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="full"
      >
        <ModalOverlay/>
        <ModalContent
          bgGradient={'linear(90deg, #C5EAEF, #D2C1EE)'}
        >
          <ModalHeader>
            <Flex gap={2}>
              <Avatar
                borderColor={(user.isLoggedInToWooorld || user.isLoggedInToWhereOnEarth) ? 'lightgreen' : 'grey'}
                name={user.alias}
                showBorder
                size="md"
                src={user.profilePhotoURL}
              />
              <Heading>{user.alias}</Heading>
            </Flex>

          </ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Stack gap={4}>

              <Accordion allowToggle>

                {(user.isLoggedInToWooorld || user.isLoggedInToWhereOnEarth) && (
                  <AccordionItem>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        📧 Message
                      </Box>
                      <AccordionIcon/>
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <UserMessage onSent={onClose} user={user}/>
                    </AccordionPanel>
                  </AccordionItem>
                )}

                <AccordionItem>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      🪑 Timeout
                    </Box>
                    <AccordionIcon/>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <UserTimeout onSent={onClose} user={user}/>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      👛 Credits
                    </Box>
                    <AccordionIcon/>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <CreditsTable user={user} />
                  </AccordionPanel>
                </AccordionItem>


              </Accordion>

            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>


      </Modal>
    </>
  );
};

export default UserModal;
