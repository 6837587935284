import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {MdDialpad, MdOutlineLock, MdOutlineLockOpen} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import {useVuplex} from '../../hooks/useVuplex';
import {helpStepSet, userIdsBlockedByUserSet, worldsSet} from '../../reducers/worldsReducer';
import {
  sendBlockedUserIdsRequest,
  sendWhereOnEarthPlay,
  sendWhereOnEarthStateRequest,
  sendWorldCreate,
  sendWorldPrivateToggle,
  sendWorldsRequest,
} from '../../vuplex';
import PageTitle from './PageTitle';
import WorldCard from './WorldCard';
import WorldJoinWithCode from './WorldJoinWithCode';
import {AiOutlineLock, AiOutlinePlus, AiOutlineUnlock} from 'react-icons/ai';
import InAppTooltip from './UnityReactComponents/InAppTooltip';
import WorldParticipants from './WorldParticipants';
import GameRoomsNewRoomButton from './GameRoomsNewRoomButton';

// import {testWorlds} from '../../helpers/testWorlds';

function QuestionBadge() {
  return <Badge
    bg="#8d7cb1"
    borderRadius="full"
    color="#303349"
    fontSize="1em"
    fontWeight="bold"
    h="1em"
    lineHeight="1em"
    w="1em"
  >
    ?
  </Badge>;
}

const GameRooms = ({}) => {
  const currentWorld = useSelector(s => s.inApp.world) || {};
  const userId = useSelector(s => s.inApp.userId) || {};
  const worlds = useSelector(s => s.worlds.worlds);
  const dispatch = useDispatch();

  const isCurrentWorldNotInList = currentWorld.isLobby || _.get(currentWorld.rules, 'isSolo') || _.get(currentWorld.rules, 'isPrivate');

  const isCurrentlyInUserHome = currentWorld.ownerId === userId && currentWorld.rules.isPrivate;

  const [isPlaying, setIsPlaying] = useState(false);
  const [hasTeam, setHasTeam] = useState(false);
  const [awaitingPrivacySetting, setAwaitingPrivacySetting] = useState(false);


  useEffect(() => {
    setAwaitingPrivacySetting(false);
  }, [_.get(currentWorld.rules, 'isPrivate')])

  useVuplex({
    blockedUsers: ({blockedUserIds}) => {
      dispatch(userIdsBlockedByUserSet(blockedUserIds));
    },
    gameState: ({hasTeam, isPlaying}) => {
      setIsPlaying(isPlaying);
      setHasTeam(hasTeam);
    },
    lobbyHelp: ({helpStep}) => {
      dispatch(helpStepSet(helpStep));
    },
    worldList: ({worlds}) => {
      dispatch(worldsSet(worlds));
    },
  });

  useEffect(() => {
    window.document.body.style.backgroundColor = '#2a2f4f';
    sendBlockedUserIdsRequest();
    sendWorldsRequest();
    sendWhereOnEarthStateRequest();
    const intervalId = setInterval(sendWorldsRequest, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const userIdsBlockedByUser = useSelector(s => s.worlds.userIdsBlockedByUser);

  return (
    <Box p={2}>
      <PageTitle title="Game Rooms - Wooorld"/>
      {currentWorld && currentWorld.rules && (
        <Card bg="#404461" color="white" borderRadius="2xl" p={2}>
          <CardHeader display="flex" justifyContent="space-between">
            <Box>
              <Heading color="white" size="md">You're in:</Heading>
              <Heading color="white" size="lg">{currentWorld.name}</Heading>
            </Box>

            <ButtonMinor
              bg={isPlaying && hasTeam ? '#9f3b6c' : '#ff3377'}
              borderRadius="xl"
              color="white"
              fontSize="3xl"
              onClick={() => {
                sendWhereOnEarthPlay();
              }}
              paddingX="1.7em"
              paddingY="1.2em"
              textTransform="uppercase"
            >
              {!isPlaying ? 'Play'
                : hasTeam ? 'Playing'
                  : 'Ask to Join Game'}
            </ButtonMinor>
          </CardHeader>


          <CardBody>

            <Flex justifyContent="space-between">
              <Stack>

                <Flex alignItems="center" gap={4}>
                  <Flex alignItems="center" gap={2}>
                    {currentWorld.rules.isPrivate ? <AiOutlineLock color="#FF8C66"/> : <AiOutlineUnlock color="#8CFF66"/>}
                    {currentWorld.rules.isPrivate ? 'Private Room' : 'Public Room'}
                  </Flex>

                  <InAppTooltip message="Share this code with a friend">
                    <Flex
                      alignItems="center"
                      bg="#303349"
                      borderRadius="md"
                      gap={2}
                      p={2}
                    >
                      <MdDialpad/>
                      <Text>Invite Code:</Text>
                      <Text>{currentWorld.joinCode}</Text>
                      <QuestionBadge/>
                    </Flex>
                  </InAppTooltip>

                </Flex>

                <Flex>
                  {/* TODO also disallow changes by non-owner on backend */}
                  <ButtonMinor
                    bg="#8f7db3"
                    borderRadius="lg"
                    color="white"
                    disabledReason={(currentWorld.ownerId !== userId) && 'Only the owner of the room can change room privacy'}
                    fontSize="xl"
                    fontWeight="normal"
                    isLoading={awaitingPrivacySetting}
                    leftIcon={currentWorld.rules.isPrivate ? <MdOutlineLockOpen/> : <MdOutlineLock/>}
                    onClick={() => {
                      sendWorldPrivateToggle();
                      setAwaitingPrivacySetting(true);
                    }}
                    paddingY="1.2em"
                    tooltip={currentWorld.rules.isPrivate ? 'Makes this room visible to anyone' : 'Makes this room invisible'}
                  >
                    {currentWorld.rules.isPrivate ? 'Make Public' : 'Make Private'}
                  </ButtonMinor>
                </Flex>
              </Stack>

              <Accordion allowToggle w="20em">
                <AccordionItem border="none">
                  <AccordionButton p={0} justifyContent="flex-end">
                    <AccordionIcon />
                    <Text align="right" color={currentWorld.isFull ? '#FF8C66' : '#BFBFBF'}>
                      {currentWorld.userCount}/8 Players
                    </Text>
                  </AccordionButton>
                  <AccordionPanel p={0}>
                    <WorldParticipants
                      bg="#303349"
                      ownerId={currentWorld.ownerId}
                      participants={currentWorld.participants}
                      userIdsBlockedByUser={userIdsBlockedByUser}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>


          </CardBody>

        </Card>
      )}


      <SimpleGrid columns={2} gap={4} p={4}>
        <GameRoomsNewRoomButton
          bg="#8f7db3"
          borderRadius="lg"
          color="white"
          fontSize="xl"
          fontWeight="normal"
          leftIcon={<AiOutlinePlus/>}
          paddingY="1.7em"
          tooltip="Leave this room and create a new one"
        />

        <WorldJoinWithCode
          bg="#8f7db3"
          borderRadius="lg"
          color="white"
          fontSize="xl"
          fontWeight="normal"
          isWhereOnEarth
          paddingY="1.7em"
          tooltip="Join someone else's private room using an invite code"
        />
      </SimpleGrid>

      <Flex display="flex" gap={2} alignItems="center" p={2}>
        <Heading color="white" size="md">Public Rooms</Heading>
        <InAppTooltip message="All public rooms">
          <QuestionBadge/>
        </InAppTooltip>
      </Flex>

      <SimpleGrid
        autoRows="max-content"
        bg="#404461"
        borderRadius="2xl"
        columns={3}
        rowGap={2}
        minH="16.5em"
        spacing={2}
        p={2}
        position="relative"
      >
        {/*{isCurrentWorldNotInList && <WorldCard world={currentWorld}/>}*/}
        {worlds.map(world => <WorldCard key={world._id} world={world}/>)}
      </SimpleGrid>
    </Box>
  );


};

export default GameRooms;
