import {Flex, Heading, Modal, ModalContent, ModalOverlay, useDisclosure} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import {sendWorldCreate} from '../../vuplex';
import WorldCardBlank from './WorldCardBlank';
import {useEnterKey} from '../../hooks/useEnterKey';

const GameRoomsNewRoomButton = ({
  isWhereOnEarth,
  ...buttonProps
}) => {
  const isLoading = useSelector(s => s.worlds.getByCodeRequest.isLoading);
  const {isOpen, onToggle, onClose} = useDisclosure();

  const handleConfirmClick = async () => {

    sendWorldCreate({
      isBing: true,
      isGame: true,
      isPrivate: true,
      isSolo: false,
    });

    onClose();
  };

  useEnterKey(handleConfirmClick);

  return (<>
    <ButtonMinor
      isRound
      onClick={() => {
        onToggle();
      }}
      {...buttonProps}
    >
      Create New Room
    </ButtonMinor>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
        borderRadius="2xl"
      />

      <ModalContent
        bg="none"
        borderRadius="2xl"
        marginBottom="0.5rem"
        marginTop="8rem"
        // maxHeight="calc(100% - 1.8rem)"
      >
        <WorldCardBlank
          headerContent={
            <Heading
              color="black.24"
              p={2}
              size="md"
              textAlign="center"
              // textShadow={`0px 2px 4px #00000077`}
            >
              Are you sure you want to leave this room and create a new one?
            </Heading>
          }
          footerContent={
            <Flex gap={4} justifyContent="center">
              <ButtonMinor
                isLoading={isLoading}
                h="3em"
                onClick={handleConfirmClick}
                tooltip={`Join world.`}
              >
                ✔ Confirm
              </ButtonMinor>

              <ButtonMinor
                h="3em"
                onClick={() => {
                  onClose();
                }}
              >
                ❌ Cancel
              </ButtonMinor>
            </Flex>
          }
          height="14em"
          landscapeTop="100px"
          landscapeHeight={150}
          world={{
            name: `A new game room`, // just for generating landscape graphics
            isLobby: false,
            rules: { isWitw: true },
          }}
        >
        </WorldCardBlank>
      </ModalContent>

    </Modal>
  </>);
};

export default GameRoomsNewRoomButton;
