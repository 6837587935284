import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader, Center,
  Flex,
  Heading,
  IconButton,
  Text,
  useToast
} from '@chakra-ui/react';
import React, {memo, useState} from 'react';
import {MdOutlinePanoramaPhotosphere, MdThumbDown, MdThumbUp, MdVrpano} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {
  pinEditFormSet,
  pinRatingCreateRequest,
  pinRatingDeleteRequest, pinsSearchResultsClear,
  pinUnsavedTempSet
} from '../../reducers/pinsReducer';
import {sendAudioClick, sendAudioHover, sendNavTo, sendPanoUnload} from '../../vuplex';
import InAppTooltip from './UnityReactComponents/InAppTooltip';
import PinOptionsMenu from './PinOptionsMenu';
import PermissionBadge from './PermissionBadge';

const PinCard = ({pin}) => {
  const {
    _id,
    address = 'unknown address',
    dislikes,
    isDislikedByUser,
    isInPrivateFolder,
    isInSharedFolder,
    isLikedByUser,
    isOwnedByUser,
    isUnsavedPinOwned,
    label,
    likes,
    location: {Latitude, Longitude},
    ownerAlias,
    ownerIsOnline,
    ownerProfilePhotoURL,
    streetViewId,
    suggestedLabel,
    zoomLevel,
  } = pin;
  const [isHovering, setIsHovering] = useState(false);
  const [isPinHovering, setIsPinHovering] = useState(false);

  const pinLabel = label === _id ? suggestedLabel : label;
  const inAppPanoId = useSelector(state => state.inApp.panoId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCurrentPano = streetViewId === inAppPanoId;
  const unsavedTempPin = useSelector(s => s.pins.pinUnsavedTemp);

  const handleCardClick = (e) => {
    sendAudioClick(e);
    if (!isCurrentPano && !streetViewId) sendPanoUnload();
    sendNavTo({
      lat: Latitude,
      lon: Longitude,
      panoId: streetViewId,
      zoom: zoomLevel,
    });
  };

  const toast = useToast();

  return (
    <Card
      bg={isOwnedByUser
        ? isHovering
          ? '#E5FEE9'
          : '#E5FEE977'
        : isHovering
          ? 'white'
          : 'whiteAlpha.700'
      }
      borderRadius="2xl"
      h="12em"
      overflow="hidden"
      // shadow={isHovering || isPinHovering ? `outline` : null}
      onPointerEnter={(evt) => {
        setIsHovering(true);
        sendAudioHover(evt);
      }}
      onPointerLeave={() => {
        setTimeout(() => setIsHovering(false), 100);
      }}
      fontSize="lg"
    >
      <CardHeader onClick={handleCardClick} p={2}>
        <Flex flex="1" gap="1" alignItems="center" justifyContent="space-between" flexWrap="nowrap">
          <Flex alignItems="center">
            <Avatar
              borderColor={ownerIsOnline ? 'lightgreen' : 'null'}
              name={ownerAlias}
              showBorder
              size="sm"
              src={ownerProfilePhotoURL}
            />
            <Heading marginLeft={1} maxW="10em" fontWeight="normal" size="md" noOfLines={1}>{ownerAlias}</Heading>
          </Flex>

          <Flex alignItems="center" gap={1}>
            <InAppTooltip message={isCurrentPano ? `Place's 360 is currently loaded` : `Place has a 360`}>
              <MdVrpano color={isCurrentPano ? '#33bbff' : '#BFBFBF'}
                        size="1.5em"/>
            </InAppTooltip>
            <PermissionBadge isPin isPrivate={isInPrivateFolder} isShared={isInSharedFolder} size="1.5em"/>
            <PinOptionsMenu pin={pin} size="1.5em"/>
          </Flex>

        </Flex>
      </CardHeader>

      <CardBody onClick={handleCardClick} p={1.5} alignItems="center" display="flex" justifyContent="center">
        <InAppTooltip message={pinLabel.length > 54 && pinLabel}>
          <Text fontSize="lg" textAlign="center" noOfLines={2}>
            {isHovering ? address : pinLabel}
          </Text>
        </InAppTooltip>

        {!_id && (
          <Center>
            <ButtonMinor
              isDisabled={isUnsavedPinOwned}
              onClick={() => {
                dispatch(pinEditFormSet(unsavedTempPin));
                navigate('../edit', {relative: 'path'});
              }}
            >
              <MdOutlinePanoramaPhotosphere/>
              &nbsp;
              Save 360
            </ButtonMinor>
          </Center>
        )}

      </CardBody>

      <CardFooter p={0} paddingX={1} marginTop={0} h="3em">

        <Flex alignItems="center" justifyContent="space-between" w="full">
          <Text flexShrink={0} marginLeft={2} noOfLines={1}>{likes} {likes === 1 ? 'like' : 'likes'}</Text>

          <Flex justifyContent="flex-end" gap={0} w="full">

            {/* 👍 */}
            {!isDislikedByUser && !isOwnedByUser && _id && (
              <IconButton
                color={isLikedByUser ? 'wooorldBlue._' : 'grey'}
                icon={<MdThumbUp size="1.5em"/>}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  const {error} = await dispatch(
                    isLikedByUser
                      ? pinRatingDeleteRequest({
                        pinId: pin._id,
                      })
                      : pinRatingCreateRequest({
                        pinId: pin._id,
                        vote: 1,
                      }));

                  if (error)
                    toast({
                      description: `error`,
                      status: 'error',
                      title: 'Failed to like pin.',
                    });
                  // dispatch(tourReactRequest({promptId, reaction: '👍'}));
                }}
                size="md"
                variant="buttonDiminished"
              />
            )}

            {/* 👎 */}
            {!isLikedByUser && !isOwnedByUser && _id && (
              <IconButton
                color={isDislikedByUser ? 'red' : 'grey'}
                icon={<MdThumbDown size="1.5em"/>}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  const {error} = await dispatch(
                    isDislikedByUser
                      ? pinRatingDeleteRequest({
                        pinId: pin._id,
                      })
                      : pinRatingCreateRequest({
                        pinId: pin._id,
                        vote: -1,
                      }));

                  if (error)
                    toast({
                      description: `error`,
                      status: 'error',
                      title: 'Failed to dislike pin.',
                    });
                  // dispatch(tourReactRequest({promptId, reaction: '👎'}));
                }}
                size="md"
                variant="buttonDiminished"
              />
            )}

          </Flex>
        </Flex>

      </CardFooter>
    </Card>
  );


};

export default memo(PinCard);
