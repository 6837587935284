import {Box} from '@chakra-ui/react';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import BackToTopButton from '../../components/BackToTopButton';
import ActionPanel from './ActionPanel';
import DevConsole from './DevConsole';
import Explore from './Explore';
import Minimap from './Minimap/Minimap';
import ModalView from './ModalView';
import Pins from './Pins';
import Tours from './Tours';
import TripPlanner from './TripPlanner/TripPlanner';
import Worlds from './Worlds';
import GameRooms from './GameRooms';

const Tablet = () => (
  <>
    <Box p={0}>
      <Routes>
        <Route path="actionPanel/*" element={<ActionPanel/>}/>
        <Route path="devConsole/*" element={<DevConsole/>}/>
        <Route path="explore/*" element={<Explore/>}/>
        <Route path="minimap/*" element={<Minimap/>}/>
        <Route path="pins/*" element={<Pins/>}/>
        <Route path="tours/*" element={<Tours/>}/>
        <Route path="tripPlanner/*" element={<TripPlanner/>}/>
        <Route path="worlds/*" element={<Worlds/>}/>
        <Route path="gameRooms/*" element={<GameRooms/>}/>
      </Routes>

      <BackToTopButton/>
      <ModalView/>
    </Box>

    {/*<ToolPanelMask borderHeight={3} borderWidth={2.2}/>*/}
  </>

);

export default Tablet;
