import {Text} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

const WorldParticipants = ({bg = 'black24', ownerId, participants, userIdsBlockedByUser}) => (<>
  <Text
    align="left"
    bg={bg}
    color="white"
    fontSize="xs"
    noOfLines={3}
    borderRadius="xl"
    p={2}
    h="70px"
  >
    {_.toPairs(participants)// {id: alias, ...} => [[id, alias], ...]
      .sort(([idA, aliasA], [idB, aliasB]) =>
        idA === ownerId
          ? -1
          : aliasA > aliasB
            ? 1
            : 0)
      .map(([id, alias], idx, coll) => {
        const isBlockedByUser = userIdsBlockedByUser[id];

        return (
          <Text
            as="span" color={isBlockedByUser ? '#FFB3AE' : 'white'}
            key={id}
            marginRight={1}
            whiteSpace="nowrap"
          >
            {ownerId === id && '👑'}
            {isBlockedByUser && '🚫'}
            {alias}
            {(idx < coll.length - 1) && ','}
            &nbsp;
          </Text>
        );
      })}
  </Text>
</>);

export default WorldParticipants;